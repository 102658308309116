import { getUniversalHelpers } from "../../helpers";
import { Org, OrgId, OrgSettings } from "@ollie-sports/models";

export async function orgSettings__server__getOrgSettingsNoAuth(p: { orgId: OrgId }): Promise<OrgSettings | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return await h.OrgSettings.getDoc(p.orgId);
}

orgSettings__server__getOrgSettingsNoAuth.auth = () => {};

// i18n certified - complete
