import { getUniversalHelpers } from "../../helpers";
import { Org, OrgId, OrgSettings } from "@ollie-sports/models";
import {
  firestoreLiftDocSubToBifrostSub,
  firestoreLiftDocsSubToBifrostSub,
  firestoreLiftQuerySubToBifrostSub
} from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgSettings__client__getOrgSettingsSubscription(p: { orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return firestoreLiftDocSubToBifrostSub(h.OrgSettings.docSubscription(p.orgId));
}

// i18n certified - complete
