import { getUniversalHelpers } from "../../helpers";
import { Org, OrgId, OrgSettings } from "@ollie-sports/models";
import {
  firestoreLiftDocSubToBifrostSub,
  firestoreLiftDocsSubToBifrostSub,
  firestoreLiftQuerySubToBifrostSub
} from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgSettings__client__getMultipleOrgSettingsSubscription(p: { orgIds: OrgId[] }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return firestoreLiftDocsSubToBifrostSub(h.OrgSettings.docsSubscription(p.orgIds));
}

// i18n certified - complete
